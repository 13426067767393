import React from 'react';
import FintesaTerms from '../../modules/apiIntegration/FintesaTerms';
import styles from "./InfoFooter.module.css";

const InfoFooter = () => {
  return (
    <footer className={styles.footer}>
      <FintesaTerms color={"hsl(0deg 3% 7% / 48%)"} />
    </footer>
  )
}

export default InfoFooter