import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DoupleForm from "./AddPaymentMethodForm";
const AddPaymentMethodConsumer = (props) => {
    const appearance = {
        theme: "stripe",
      };
      const options = {
        appearance,
      };
    async function LoadStripe(){
        if(props && props.accountId){
        let stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
         return await loadStripe(stripe_publish_key, { stripeAccount: props.accountId});
    }
      }
      const stripePromise = LoadStripe();
      
    return ( <>
    <Elements stripe={stripePromise} options={options} >
                <DoupleForm buttonTextColor={props.buttonTextColor} buttonColor={props.buttonColor} accountId={props.accountId} setPaymentMethodUpdated={props.setPaymentMethodUpdated}/>
              </Elements>
    </> );
}
 
export default AddPaymentMethodConsumer;