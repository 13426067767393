import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import AxiosInstanceUserServer from "../../components/axios/AxiosInstanceUserServer";
import "../../components/payment_intent/paymentIntent.css";
import FintesaTerms from "./FintesaTerms";
import IntegrationsMessage from "../../components/modals/IntegrationsMessage";

const specialCurrencies= ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga', 'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf']


const ApiPaymentConfiremed = (props) => {
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);
  const [priceValue, setPriceValue] = useState(10);
  const [currency, setCurrency] = useState('usd');
  const [factor, setFactor] = useState(100);
  const [showShippingMessage, setShowShippingMessage] = useState(false);

  let {merchant_uuid} = useParams();
  useEffect(() => {
    if (props.intent.metadata && props.intent.metadata.currency_type==="jod") {
      let jodFactor = 0.708;
      if(merchant_uuid === "gZ0v_d5f7188a-de2e-4dec-a33f-9497f65a9a39") {
        jodFactor = 0.731;
      }
      setFactor(100/jodFactor);
      setCurrency("JOD");
    } else if (specialCurrencies.includes(props.intent.currency)) {
      setFactor(1);
      setCurrency(props.intent.currency.toUpperCase());
    } else {
      setCurrency(props.intent.currency.toUpperCase());
    }
    setPriceValue(props.intent.amount)
  }, []);

  useEffect(() => {
    get_display_name();
  }, [props.account]);
  useEffect(() => {
    if(props.intent && props.intent.metadata && props.intent.metadata.with_shipping) {
      setShowShippingMessage(true);
    }
  }, [props.intent])
  const get_display_name = async () => {
    setLoading(true);
    try {
      await AxiosInstanceUserServer.get(
        `/merchant/get-display-name/${props.account}`
      ).then((res) => {
        setDisplayName(res.data);
      });
    } catch (error) {
      // To be Change by aws To Message (Toast)
      // console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="secondChild">
      {showShippingMessage &&<IntegrationsMessage show={showShippingMessage} setShow={setShowShippingMessage} message={"Your shipment is in processing, please check your email for details!"} title={"Processing Shipment"} />}
      <div className="container_success-checkmark_">
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </div>
        {props.fromBack ? <div>
          <h3>
            Thanks for your {props.fromSubscription ? "subscription" : "payment"}
          </h3>
          <p className="success_message_text">
            This link has already been used
          </p>
        </div> : 
        <div className="success_message">
          <h3>
            Thanks for your { props.intent.invoice ? "subscription" : "payment" }
          </h3>
          <p className="success_message_text">
            A payment to {displayName} will appear on your statement.
          </p>
          <div className="card_success_message">
            <span className="success_message_display_name">{displayName}</span>
            <span>
              {currency.toUpperCase()} {(priceValue/factor).toFixed(2)}
            </span>
          </div>
        </div>
        }
        <FintesaTerms color={"hsl(0deg 3% 7% / 48%)"} />
      </div>
    </div>
  );
};

export default ApiPaymentConfiremed;
