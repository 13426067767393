import React from 'react';
import styles from './InfoBody.module.css';
import QuantityInput from '../inputs/QuantityInput';

const InfoBody = ({elementName, price, price_value, description, showQuantityModal, quantity}) => {
  return (
    <div className={styles.body}>
      <h1>
        {elementName}
      </h1>
      <p className={styles.price}>
        {price}
      </p>
      {showQuantityModal && 
        <QuantityInput quantity={quantity} price_value={price_value} showQuantityModal={showQuantityModal} />
      }
      <p>
        {description}
      </p>
    </div>
  )
}

export default InfoBody