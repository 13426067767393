import { useEffect, useState } from "react";
import { Modal,Button, Form, Spinner } from "react-bootstrap";
import md5 from 'md5-hash'
import AxiosInstanceUserServer from "../axios/AxiosInstanceUserServer";
import { toast } from "react-toastify";

const IntegrationVerification = (props) => {
  const {email, checkPlan} = props;
  const handleClose = () => props.setShow(false);

  const [verificationCode, setVerificationCode] = useState("");
  const [NotValid, setNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(59);

  const counterDecrementer = (count) => {
    if (count > 0) {
      setCounter(count - 1);
      setTimeout(() => {
        counterDecrementer(count - 1);
      }, 1000);
    } else {
      setCounter(0);
    }
  };
  useEffect(() => {
    setCounter(59);
  }, [props.show])
  const resendVertifcation = async () => {
    setCounter(59);
    counterDecrementer(59);
    props.sendVerificationEmail();
  };
  useEffect(() => {
    counterDecrementer(counter);
  }, [])
  const checkValid = () => {
    // setNotValid( false )
    // setLoading( true )
    // const encrybtedCode = md5(verificationCode)

    // Request check if Valid
    setNotValid(false);
    // setLoading(true);
    const encrybtedCode = md5(verificationCode);
    // return;
    AxiosInstanceUserServer.post("/verify-email-code", {
      email: email,
      verification_code: encrybtedCode,
    })
      .then((res) => {
        if (res.data && res.data.status === "Success") {
          checkPlan();
          // setVerfied(true);
          // setRightVerificationCodeMessage(true);
          // setTimeout(() => {
          //   setRightVerificationCodeMessage(false);
          //   handleClose();
          // }, 2000);
        } else {
          setNotValid(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal show={props.show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px", color: "gray" }}>
            Add Verification Code
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            We have sent you verification code for your email{" "}
            <span style={{ fontWeight: "bolder" }}>{props.email}</span>
          </p>
          <Form
            style={{
              backgroundColor: "white",
              width: "100%",
              maxWidth: "500px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "1rem",
            }}
          >
            <Form.Group controlId="verification_code" className="mb-3">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="number"
                name="verification_code"
                defaultValue=""
                required
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
          {NotValid && <p style={{ color: 'red', marginTop: '0.5rem'}}>Wrong Vertification Code</p>}
        </Modal.Body>

        <Modal.Footer style={{justifyContent: "flex-end"}}>
        {counter > 0 && <span>{`You can resend an email after 00:${counter}`}</span>}

          <Button
            variant="secondary"
            disabled={loading || counter > 0}
            onClick={resendVertifcation}
          >
            resend Code
          </Button>
          <Button
            variant="primary"
            onClick={checkValid}
            disabled={loading || !verificationCode}
            style={{ marginLeft: "0.5rem" }}
          >
            {loading ? <Spinner animation='border' variant="light" size="sm" /> : "confirm"}
          </Button>

        </Modal.Footer>
      </Modal>
      
    </div>
  );
};

export default IntegrationVerification;
