import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Alert, Spinner } from "react-bootstrap";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useParams, useNavigate } from "react-router-dom";
import PhoneNumberInput from "../../components/inputs/PhoneNumberInput";
import TermsAndConditions from "./TermsAndConditions";
import FintesaTerms from "./FintesaTerms";

const ApiCheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { merchant_uuid, client_secret } = useParams();

  const [email, setEmail] = useState(props.sub ? props.email : "");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(null);
  const [nameOnCard, setNameOnCard] = useState("");
  const [isChecked, setIsChecked] = useState(!props.terms);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const phoneRegex = /^(\s*|\+\d{1,3}?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4})$/;
    setIsPhoneNumberValid(phoneRegex.test(phoneNumber));
  }, [phoneNumber]);

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);
    setMessage("");
    const return_url = props.success_url || `${process.env.REACT_APP_FRONTEND_URL_PAYMENT}/payment-success/${merchant_uuid}/${client_secret}${props.sub ? "/sub" : "/one-time"}`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url,
        payment_method_data: {
          billing_details: {
            name: nameOnCard,
            email: email,
            phone: phoneNumber,
          },
        },
      },
    });

    if (error) {
      setMessage(error.message);
      setIsLoading(false);
    } else {
      navigate(return_url);
    }
  };

  const handleNextStep = () => {
    if (!email) {
      setMessage("Please enter a valid email address.");
      return;
    }
    if (!isPhoneNumberValid) {
      setMessage("Please enter a valid phone number.");
      return;
    }
    if (!nameOnCard) {
      setMessage("Please enter the name on the card.");
      return;
    }
    setMessage("");
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setMessage("");
    setCurrentStep(currentStep - 1);
  };

  const buttonStyle = {
    backgroundColor: props.branding.buttonColor,
    color: props.branding.buttonTextColor,
    borderColor: props.branding.buttonColor,
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    display: 'block',
    marginTop: '10px'
  };

  const paymentElementOptions = { layout: "tabs" };

  return (
    <div className="d-flex flex-column">
      {currentStep === 1 && (
        <Form id="payment-form" style={{ border: message ? "1px solid red" : "none" }}>
          <Form.Label htmlFor="email" style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}>Email</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              id="email"
              aria-describedby="basic-addon3"
              style={{ color: "rgb(33,37,41)" }}
              required
              value={email}
              disabled={!!props.email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            payment_uuid={merchant_uuid}
            isValid={isPhoneNumberValid}
          />
          <Form.Label htmlFor="card-name" style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}>Name on card</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              id="card-name"
              aria-describedby="basic-addon3"
              style={{ color: "rgb(33,37,41)" }}
              required
              onChange={(e) => setNameOnCard(e.target.value)}
            />
          </InputGroup>
          <Button
            style={buttonStyle}
            onClick={handleNextStep}
          >
            Next
          </Button>
        </Form>
      )}
      {currentStep === 2 && (
        <Form id="payment-form" onSubmit={handleSubmit} style={{ border: message ? "1px solid red" : "none" }}>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          {props.terms && (
            <TermsAndConditions
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              terms={props.terms}
            />
          )}
          <div className="d-flex gap-3">
            <Button
              style={buttonStyle}
              onClick={handlePreviousStep}
            >
              Back
            </Button>
            <Button
              disabled={isLoading || !stripe || !elements || !isChecked || !isPhoneNumberValid}
              style={buttonStyle}
              type="submit"
            >
              {isLoading ? (
                <Spinner animation="border" style={{ color: props.branding.buttonTextColor }} />
              ) : (
                "Pay"
              )}
            </Button>
          </div>
        </Form>
      )}
      {message && (
        <Alert variant="danger" className="alert-message" style={{ color: "red", marginTop: "1rem", textAlign: "center" }} id="payment-message">
          {message}
        </Alert>
      )}
      <FintesaTerms color={"hsl(0deg 3% 7% / 48%)"} />
    </div>
  );
};

export default ApiCheckoutForm;
