import React from 'react';
import FintesaLogo from "../../images/Fintesa.png";
import styles from "./InfoHeader.module.css";


const InfoHeader = () => {
  return (
    <header className={styles.header}>
      <img src={FintesaLogo} alt='Fintesa Logo' loading='lazy' />
    </header>
  )
}

export default InfoHeader