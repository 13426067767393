import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Spinner , Form , InputGroup, Button} from "react-bootstrap";
import CreateSubscription from "./CreateSubscription"
import { useParams } from "react-router-dom";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import Vertificatoin from "../module/Vertification";
export default function CustomerRegister(props) {

    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [isSubmitted, setIsSubmitted] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [customerId, setCustomerId] = useState(false);
    const [ subscriped , setSubscriped] = useState(false);
    const [verfied , setVerfied ] = useState(false)
    const [show , setShow ] = useState( false )
    let { payment_uuid, product_id, price_id } = useParams();
      const handleSubmit = async(event) => {
        event.preventDefault();
       setSubscriped( false )
        customer_existence_check() 
        if(verfied){
        }
      };
      function showVertification( ){
        setShow( true )
      }
      async function CheckUserVertification(){
        return await  AxiosInstanceMENACartServer.post(`/send-verification-code/${props.account_id}` , {email, product_id}).then( res => {

          showVertification()
        }).catch(er => {
        })
      }
    const customer_existence_check = async () => {
      setIsLoading(true);
    return await AxiosInstanceMENACartServer.post(
        `/customer/retrieve/${props.account_id}`,
        { email:email }
      ).then(res => {
        if (res.data && res.data.data &&res.data.data.length >  0 ){
          setIsRegistered(true)
          // console.log(res.data.data[0].id);
          setCustomerId(res.data.data[0].id)
          return(res.data.data[0].id)
        }
        else{
          return('')
        }
      }).then(res => {
        if( res ){
check_if_already_subscriped(res)
        }else{
          // setIsSubmitted(true)
          CheckUserVertification()
        }
        
      }).finally( ()=>{
        setIsLoading(false)
      });
    };
    const check_if_already_subscriped = async (customerId) => {
      setIsLoading(true);
      try {
        if (props.account_id) {
        return  await AxiosInstanceMENACartServer.post(
            `/subscription/retrieve/${props.account_id}`,
            { product_id:product_id,customer_id:customerId }
             ).then(res=>{
               if (!(res.data.data && res.data.data.length > 0 )){
                   setSubscriped(false)
                   CheckUserVertification()
               } else{
                setSubscriped(true)
               }
             }).catch( error => {
             })
        }
        // setClientSecret(res.data);
      } catch (error) {
    // To be Change by aws To Message (Toast)
        // console.log(error);
      }
      setIsLoading(false);
    };
      return (
        <div className="App">
          <Vertificatoin show={show} setShow={setShow} setVerfied={setVerfied} product_id={product_id} account_id={props.account_id} email={email} setIsSubmitted={setIsSubmitted}/>
        {!isSubmitted&&
        <form onSubmit={handleSubmit} className="login-form">
         <div >
          <h2>Create subscription</h2>
         <Form.Label
                  htmlFor="card-name"
                  style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
                >
                  Email:
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="email"
                    type='email'
                    aria-describedby="basic-addon3"
                    style={{ color: "rgb(33,37,41)" }}
                    required
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                </InputGroup>
                <Form.Label
                  htmlFor="card-name"
                  style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
                >
                  Name:
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="Name"
                    aria-describedby="basic-addon3"
                    style={{ color: "rgb(33,37,41)" }}
                    required
                    onChange={(e) => {
                      setName(e.target.value)
                    }}
                  />
                </InputGroup>
                <Button variant="primary" type="submit"> {isLoading ? (
                             <span>
                              <Spinner  animation="border" size="sm" style={{backgroundColor:'#5469d4', width:'1.5rem', height: '1.5rem' , border: '0.35rem solid white', borderRightColor:'transparent'}}/>
                             </span>
                          ) : (
                           <span>Create subscription</span>
                          )}</Button>
                {subscriped && <span style={{color: 'red',display: 'block', marginTop: '0.75rem'}}>Your already have active subscription For this product</span>}
         </div>
                
      </form>}
        {isSubmitted  && !isLoading && verfied && <CreateSubscription email={email} name={name} isRegistered={isRegistered} customerId={customerId}/>}
        </div>
      );
    }