import React from 'react'

const ShippingTr = ({shippingFees, color}) => {
  return (
    <tr style={{borderBottom:`1px solid ${color}`}}>
      {shippingFees ?
        <>
          <td colSpan="2">Shipping Fees</td>
          <td style={{fontWeight:'bold'}}>{shippingFees}</td>
        </>
        :<>
          <td style={{fontWeight:'bold'}} colSpan="3">Enter Address Data to Be Calculated!</td>
        </>
      }
    </tr>
  )
}

export default ShippingTr