import React, { useEffect, useState } from 'react';
import {loadStripe} from "@stripe/stripe-js";


import styles from "./IntegrationPayment.module.css";
import { Elements } from '@stripe/react-stripe-js';
import IntegrationPayForm from './IntegrationPayForm';

const IntegrationPayment = ({clientSecret, email}) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY));
  }, []);
  return (
    <div className={styles.container}>
      <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
        <IntegrationPayForm clientSecret={clientSecret} email={email} />
      </Elements>
    </div>
  )
}

export default IntegrationPayment