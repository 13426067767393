import React, { useEffect, useState } from "react";
import ApiCheckoutForm from "./ApiCheckoutForm";
import PayFormSkeleton from "../../components/skeletons/PayFormSkeleton";
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

const ApiPayment = (props) => {
  const [stripePromise, setStripePoromise] = useState(null);

  useEffect(() => {
    setStripePoromise(loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY, {
      stripeAccount: props.account,
    }));
  }, [])

  return(!(props.cs && stripePromise) ?
    <PayFormSkeleton />:
    <div className="secondChild">
       <Elements stripe={stripePromise} options={{clientSecret: props.cs}} >
        <ApiCheckoutForm merchant_uuid={props.merchant_uuid} withShipping={props.withShipping} success_url={props.success_url} email={props.email} sub={props.sub} terms={props.terms} branding={props.branding} />
      </Elements>
    </div>
  )
}

export default ApiPayment;