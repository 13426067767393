import React from 'react'
import { Modal } from 'react-bootstrap'

const IntegrationsMessage = ({title, message, show, setShow}) => {
  const closeHandler = () => {
    setShow(false);
  }
  return (
    <Modal show={show} onHide={closeHandler}>
      <Modal.Header closeButton>
        <h3>{title}</h3>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
    </Modal>
  )
}

export default IntegrationsMessage