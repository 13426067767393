import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import currencyData from '../../currencies.json';
import ShippingTr from "./ShippingTr";

const specialCurrencies= ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga', 'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf']

const TableOfItems = (props) => {
  const [factor, setFactor] = useState(100);
  const [amountFactor, setAmountFactor] = useState(100);
  const [currency, setCurrency] = useState("usd");
  const {merchant_uuid} = useParams();

  useEffect(() => {
    if (props.info.metadata.currency_type === "jod") {
      let jodFactor = 0.708;
      if(merchant_uuid === "gZ0v_d5f7188a-de2e-4dec-a33f-9497f65a9a39") {
        jodFactor = 0.731;
      }
      setAmountFactor(100/jodFactor);
      setFactor(100);
      setCurrency("JOD");
    } else if (specialCurrencies.includes(props.info.currency)) {
      setAmountFactor(1);
      setFactor(1);
      setCurrency(props.info.currency.toUpperCase());
    } else {
      setCurrency(props.info.currency.toUpperCase());
    }
  }, []);
  let jsonList = props.info.metadata.items_list;
  for (let i = 1; i<5; i++) {
    jsonList += props.info.metadata[`items_list${i}`]
  }
  const list = JSON.parse(jsonList);
  const amount = props.info.amount;
  const symbol = props.info.metadata.symbol ?? '';  // the symbol in the backend is empty
  const currencySymbol = currencyData.find(item => item.name === currency)?.symbol || '';
  return (
        <div style={{marginTop:'8rem'}}>
          <div style={{marginBottom:'1rem', display:"flex", flexDirection:'column' ,borderTop:'none',justifyContent:'left'}}>
          <span style={{fontSize:'12px'}}>Total amount</span>

          <span style={{fontSize:'24px', fontWeight:'bold'}}>{(amount/amountFactor).toFixed(2)} {currencySymbol}</span>
          </div>

    <table style={{color: props.style.color, borderTopColor: props.style.color}}>
       <thead>
      </thead> 
      <tbody>
        {list.map((element,idx) => 
          <tr key={idx} style={{borderBottom:`1px solid ${props.style.color}`}}>

            <td>{element.item}</td>

            <td>{(element.price/factor).toFixed(2)}{element.quantity > 1 ? ` x ${element.quantity}` : " x 1"}</td>
            <td style={{fontWeight:'bold'}}>{element.quantity > 1 ? (element.price*element.quantity/factor).toFixed(2) : (element.price/factor).toFixed(2)} {currencySymbol}</td>
          </tr>
        )}
        {props.info.metadata.with_shipping &&
          <ShippingTr shippingFees={props.info.metadata.shipping_fees} color={props.style.color} />
        }
      </tbody>
    </table>
    </div>

  );
};

export default TableOfItems;