import React, { useEffect, useState } from "react";
import AxiosInstanceUserServer from "../axios/AxiosInstanceUserServer";
import { Toast } from "react-bootstrap";
import PaymentCompleted from "./PaymentCompleted";
import QuantityInput from "../inputs/QuantityInput";
import PayForm from "./PayForm";
import { ref  , getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { Spinner, Alert } from "react-bootstrap";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import QuantityModal from "../modals/QuantityModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PayFormSkeleton from "../skeletons/PayFormSkeleton";

export default function CheckoutForm(props) {
  const [email, setEmail] = useState(props.email ? props.email : '');
  const [isLoading, setIsLoading] = useState(false);
  const [ isStylesLoading , setIsStylesLoading ] = useState(true)
  const [isChecked, setIsChecked] = useState(false);
  const [product, setProduct] = useState({});
  const [price, setPrice] = useState({});
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [errorToast, setErrorToast ] = useState(false)
  const [termsAndConditions , setTermAndConditions ] = useState('')
  const [ termsAndConditionsError , setTermsAndConditionsError ] = useState(false)
  const [ errorAlert , setErrorAlert ] = useState(false)
  const [quantityModalShow, setQuantityModalShow] = useState(false);
  const specialCurrencies= ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga', 'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf']
  const [buttonBackgroundColor , setButtonBackgroundColor ] = useState('#5469d4')
  const [buttonTextColor ,setButtonTextColor ] = useState('white');
  const [backgroundColor , setBackgroundColor ] = useState('#5469d4')
  const [textColor , setTextColor ] = useState('white')
  const [ logo , setLogo ] = useState('')
  const [success_url, setSuccess_url] = useState("");

  const quantity = props.quantity;
  const setQuantity = props.setQuantity;
   //class to body
  document.body.classList.add("payment_intent_style");
  const handleCheckboxValue = (e) => {
    setIsChecked(e.target.checked);
  };
  
  
  useEffect(() => {
    const get_product = async () => {
      setIsLoading(true);
      try {
        await AxiosInstanceMENACartServer.post(
          `/get-product`,
          {
            product_id: props.payment_info["product_id"],
            price_id: props.payment_info["price_id"],
            merchant_id: props.payment_info["account_id"],
          }
        ).then((res) => {
          setProduct(res.data["product"]);
          setPrice(res.data["price"]);
          if(res.data && res.data.product.metadata.success_url) {
            setSuccess_url(res.data.product.metadata.success_url);
          }
        });
      } catch (error) {
        // To be Change by aws To Message (Toast)
        setErrorToast(true)
        setTimeout(()=>{
          setErrorToast(false)
        },3000)
      }
      setIsLoading(false);
    };
    
    const get_terms_and_conditions = async () => {
      if(props.payment_info["account_id"]){
        await AxiosInstanceUserServer.get(
          `/merchant/get-terms-and-condtions/${props.payment_info["account_id"]}`
        )
          .then((res) => {
            setTermAndConditions(res.data);
    
    
          if(!res.data ){
    
            setIsChecked(true)
          }} ).catch( error => {
           // To be Change by aws To Message (Toast)
           setErrorToast(true)
            setTimeout(()=>{
              setErrorToast(false)
            },3000)
            setErrorAlert(true)
            setIsChecked(false)
            setTermsAndConditionsError(true)
          })
      }
    }
    async function getBranding(){
      
     await AxiosInstanceUserServer.get(`/branding/retrieve-branding/${props.payment_info["account_id"]}`).then(
        success => {
          // console.log(success.data);
          setButtonBackgroundColor( success.data.buttonColor)
          setButtonTextColor(success.data.buttonTextColor)
          setBackgroundColor(success.data.backgroundColor)
          setTextColor(success.data.textColor)
         
        return success.data
        }
      ).then(data =>{
        if(data.selectedImage ){
          return getDownloadURL( ref(storage , data.selectedImage)) 
        }else{ 
          return false
        }
      }).then( url =>{
       if( url){
        setLogo(url)
       }
      }).catch( error => {
        // console.log(error);
        setErrorToast(true)
        setTimeout(()=>{
          setErrorToast(false)
        },3000)
    
      // To be Change by aws To Message (Toast)
      }).finally( ()=>{
        setIsStylesLoading(false)
      })
    }
    if(props.payment_info["account_id"]){
        get_product();
        get_terms_and_conditions()
        getBranding()
    }
  }, []);
  
  async function LoadStripe(){
    let stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
     return await loadStripe(stripe_publish_key, {
      stripeAccount: props.account_id,
    });
  }
  const stripePromise = LoadStripe();

          const paymentElementOptions = {
            layout: "tabs",
          };
          let price_value=0
          let currency=''
          if(price.metadata && price.metadata.currency_type==="jod") {
            let factor = 0.708;
            if (props.payment_uuid === "gZ0v_d5f7188a-de2e-4dec-a33f-9497f65a9a39") {
              factor = 0.731;
            }
            price_value= (price["unit_amount"]*factor)/100
            currency='jod'
          }
          else if (specialCurrencies.includes(price["currency"])) {
            price_value= price["unit_amount"]
            currency=price["currency"]
          }
          else {
            price_value= price["unit_amount"]/100
            currency=price["currency"]
          }
          return (
            <>
            {quantityModalShow && 
                <QuantityModal price={price} show={quantityModalShow} setShow={setQuantityModalShow} setQuantity={setQuantity} quantity={quantity} createPaymentIntent={props.createPaymentIntent} price_value={price_value}/>
            }
            {isStylesLoading ?   <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div> : <>
            
              {product["id"] && price["id"] && (
                
                <div className="container_payments">
                  <Toast style={{
                    position:'absolute',
                    top: '0px',
                    height: '30px',
                    width:' 100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1rem',
                    backgroundColor: 'rgb(230, 159, 159)',
                    border: 0,
                  }} show={errorToast}>
      <Toast.Body>Somthing went wrong!</Toast.Body>
    </Toast>
                  <Alert variant="danger" style={{position: "absolute", top: 0, left:0, right: 0, textAlign: "center"}} show={errorAlert}>Somthing Went Wrong</Alert>
                  <div className="background_element" style={{backgroundColor: backgroundColor}}>
                    
                    <div className="text_payments">
                      <div className="header_payments_text">
                      { logo &&   <img src={logo} alt="Logo" className="logo" />}
                      <p className="updated_text_payments" style={{color:textColor, fontSize: '2rem', marginTop:'1rem'}}>{product.name}</p>
                        {
                          !price.recurring ? (
                          <h3 className="price_text_payments" style={{color:textColor}}>
                          {currency.toUpperCase()} {(price_value*quantity).toFixed(2)}
                          </h3>
                          ) : (
                        <h3 className="price_text_payments" style={{color:textColor}}>
                          {currency.toUpperCase()} {price_value.toFixed(2)}{price.recurring.interval_count > 1 ? "/ "+price.recurring.interval_count+" "+price.recurring.interval+"s": ""}{price.recurring.interval && price.recurring.interval_count == 1 ? "/ "+price.recurring.interval: ""}
                        </h3>
                          )

                        }
                        {!price.recurring && <QuantityInput showQuantityModal={setQuantityModalShow} quantity={quantity} price_value={price_value} textColor={textColor}/>}
                        <p className="desc_text_payments" style={{color:textColor}}>{product.description}</p>
                        <div className="footer_payments_text" style={{color:textColor}}>
                          <span>
                            Powered by <a target="_blank" href="https://fintesa.co/" style={{color:textColor}}>Fintesa</a>
                          </span>{" "}
                          |{" "}
                          <span className="terms_footer_payments_text" >
                            <a href="https://fintesa.co/terms-and-conditions" target="_blank" style={{color:textColor}}>Terms</a>
                          </span>
                          {/* Edit when we have Privicy Page:  Aws From word */}
                          {/* <span>
                            <a href="#" style={{color:textColor}}>Privicy</a>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>  
                  {paymentCompleted ? <PaymentCompleted /> : <div className="form_payments">
                    {props.updateForm ? 
                    <>
                      <PayFormSkeleton />
                    </> :
                    <Elements options={{clientSecret:props.clientSecret}} stripe={stripePromise}>
                      <PayForm success_url={success_url} quantity={quantity} payment_uuid={props.payment_uuid} payment_info={props.payment_info} price={price} buttonTextColor={buttonTextColor} buttonBackgroundColor={buttonBackgroundColor} isLoading={isLoading} setIsLoading={setIsLoading} email={email} termsAndConditions={termsAndConditions} termsAndConditionsError={termsAndConditionsError} isChecked={isChecked} setIsChecked={setIsChecked} currency={currency} productName={product.name}/>
                    </Elements>
                    
                    }
                  </div>}
                </div>
              )}
            </> }
            </>
          );}