import React, { useState, useEffect } from "react";
import "./ApiIntegration.css";
import { useParams } from "react-router-dom";
import { useBrandingAndTerms } from "./useBrandingAndTerms";
import Information from "./Information";
import { Spinner } from "react-bootstrap";
import ApiPaymentConfiremed from "./ApiPaymentConfirmed";
import SubscriptionInformation from "./SubscriptionInformation";

const ApiSuccess = () => {
  const {merchant_uuid, client_secret, sub} = useParams();
  const [isLoading, setLoading] = useState(false);
  const [intent, setIntent] = useState({});
  const BrandingAndTerms = useBrandingAndTerms(merchant_uuid, setLoading);
  const [paymentIntent, setPaymentIntent] = useState("");

  useEffect(() => {
    let decryptedArray = client_secret.split("_secret")
    setPaymentIntent(decryptedArray[0]);
  },[])

  return (
    isLoading 
    ? <div className="integration-spinner">
      <Spinner />
    </div>
    :<div className="integration-parent">
      {sub === "one-time" ?
      <Information intent={intent} setIntent={setIntent} uuid={merchant_uuid} intentID={paymentIntent} branding={BrandingAndTerms.branding} downloadURL={BrandingAndTerms.downLoadURL} />
      :
      <SubscriptionInformation setIntent={setIntent} intent={intent} uuid={merchant_uuid} intentID={paymentIntent} branding={BrandingAndTerms.branding} downloadURL={BrandingAndTerms.downLoadURL} />
      }
      {intent && intent.data && <ApiPaymentConfiremed intent={intent.data} account={BrandingAndTerms.accountId} />}
    </div>
  );
}

export default ApiSuccess;