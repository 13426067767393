import React, { useEffect, useState } from 'react';
import InfoHeader from './InfoHeader';
import InfoBody from './InfoBody';
import styles from './SubscriptionInfo.module.css';
import InfoFooter from './InfoFooter';

const SubscriptionInfo = ({priceObject, productName, productDescription, showQuantityModal, quantity}) => {
  const [price, setPrice] = useState();
  const [price_value, setPrice_value] = useState()

  useEffect(() => {
    let amount = (priceObject.unit_amount/100).toFixed(2);
    if (priceObject.recurring) {
      setPrice("$"+amount + " / month");
    } else {
      if (quantity) {
        amount = (amount * quantity).toFixed(2);
      }
      setPrice("$"+amount);
    }
    setPrice_value(priceObject.unit_amount/100);
  }, [quantity])

  return (
    <div className={styles.container}>
      <div>
        <InfoHeader />
        <InfoBody
          elementName={productName}
          price={price}
          price_value={price_value}
          description={productDescription ? productDescription : ""}
          showQuantityModal={showQuantityModal}
          quantity={quantity}
          />
      </div>
      <InfoFooter />
    </div>
  )
}

export default SubscriptionInfo