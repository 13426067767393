import React, { useState } from 'react'
import { FintesaLogo } from '../components/payment_intent/FintesaLogo';
import { FintesaEmail } from './FintesaEmail';

export const FintesaPlans = () => {
    const [verfied, setVerfied] = useState(false)
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
  return (<>
  { verfied ?    <div className='fintesa-plans-container'>
    <FintesaLogo email={email} name={name} />
  </div> :  <FintesaEmail setVerfied={setVerfied} setEmail={setEmail} setName={setName} /> }
  </>)
}
