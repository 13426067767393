import { useState, useEffect } from "react";
import AxiosInstanceMENACartServer from "../../components/axios/AxiosInstanceMENACartServer";
import AxiosInstanceUserServer from "../../components/axios/AxiosInstanceUserServer";
import { ref  , getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";


const useBrandingAndTerms = (uuid, loading) => {
  const [accountId, setAccountId] = useState("");
  const [termAndConditions, setTermAndConditions] = useState("");
  const [downLoadURL, setDownloadURL] = useState("");
  const [branding, setBranding] = useState();

  useEffect(() => {
    extract_merchant_id_from_merchant_payment_uuid();
    if(!accountId) return;
    Promise.allSettled([getBranding(),get_terms_and_conditions()])
    .then((res) => {
    })
    .finally(() => {
      loading(false);
    });
  }, [accountId]);

  const extract_merchant_id_from_merchant_payment_uuid = async () => {
    try {
      loading(true);
      let res = await AxiosInstanceMENACartServer.get(
        `/get_merchent_id/${uuid}`
      );
      setAccountId(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const get_terms_and_conditions = async () => {
    if (accountId) {
      await AxiosInstanceUserServer.get(
        `/merchant/get-terms-and-condtions/${accountId}`
      )
        .then((res) => {
          setTermAndConditions(res.data);
        })
        .catch((error) => {
          // To be Change by aws To Message (Toast)
          console.error(error);
        });
    }
  };

  const getBranding = async () => {
    if (!accountId || accountId === "") return;
    let branding = await AxiosInstanceUserServer.get(
      `/branding/retrieve-branding/${accountId}`
    );
    if (branding.data) {
      setBranding(branding.data);
      if (branding.data.selectedImage) {
        getDownloadURL(ref(storage , branding.data.selectedImage))
        .then((res) => {
          setDownloadURL(res);
        });
      } else {
        setDownloadURL("");
      }
    } else {
      console.error("something went wrong retrieving branding object!");
    }
  };

  return { branding, termAndConditions, downLoadURL, accountId };
};

export { useBrandingAndTerms };
