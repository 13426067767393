import React, { useEffect, useState } from 'react'
import "./ApiIntegration.css";
import { Spinner } from 'react-bootstrap';
import SubscriptionInformation from './SubscriptionInformation';
import { useParams } from 'react-router-dom';
import { useBrandingAndTerms } from './useBrandingAndTerms';
import ApiPayment from './ApiPayment';
import ApiPaymentConfiremed from './ApiPaymentConfirmed';

const ApiSubscriptionIntegration = () => {
  const {merchant_uuid, client_secret} = useParams();
  const [isLoading, setLoading] = useState(false);
  const [intent, setIntent] = useState(null);
  const BrandingAndTerms = useBrandingAndTerms(merchant_uuid, setLoading);
  const [paymentIntent, setPaymentIntent] = useState("");
  useEffect(() => {
    let paymentIntentArray = client_secret.split("_secret")
    setPaymentIntent(paymentIntentArray[0]);
  },[])

  return (
    isLoading ? <div className="integration-spinner">
      <Spinner />
    </div>
    :<div className="integration-parent">
      <SubscriptionInformation setIntent={setIntent} intent={intent} uuid={merchant_uuid} intentID={paymentIntent} branding={BrandingAndTerms.branding} downloadURL={BrandingAndTerms.downLoadURL} />
      {intent && intent.data && intent.data.status === "succeeded" ?
        <ApiPaymentConfiremed fromSubscription={true} fromBack={true} intent={intent.data} account={BrandingAndTerms.accountId} /> 
        :<ApiPayment merchant_uuid={merchant_uuid} success_url={intent?.data?.metadata?.success_url} sub={true} email={intent?.data?.metadata.email} terms={BrandingAndTerms.termAndConditions} branding={BrandingAndTerms.branding} account={BrandingAndTerms.accountId} cs={client_secret} />
      }
    </div>
  )
}

export default ApiSubscriptionIntegration