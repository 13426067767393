import React from 'react';
import styles from "./SectionContainer.module.css";

const SectionContainer = ({children}) => {
  return (
    <section className={styles.container}>
      {children}
    </section>
  )
}

export default SectionContainer