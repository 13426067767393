import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import TableOfItems from "./TableOfItems";
import FintesaTerms from "./FintesaTerms";
import ApiIntegrationInfoSkeleton from "../../components/skeletons/ApiIntegrationInfoSkeleton";
import useIntentData from "./useIntentData";

const Information = (props) => {
  const paymentIntent = useIntentData(props.intentID, props.uuid);

  const [style, setStyle] = useState({
    backgroundColor: props.branding && props.branding.backgroundColor ? props.branding.backgroundColor : "black",
    color: props.branding && props.branding.textColor ? props.branding.backgroundColor : "white",
  });

  useEffect(() => {
    setStyle({
      backgroundColor: props.branding && props.branding.backgroundColor ? props.branding.backgroundColor : "black",
      color: props.branding && props.branding.textColor ? props.branding.textColor : "white",
    })
  }, [props.branding]);

  useEffect(() => {
    if(props.setIntent) {
      props.setIntent(paymentIntent);
    }
  }, [paymentIntent])

  return (
    <div style={style}>
      {!paymentIntent ? <ApiIntegrationInfoSkeleton /> :
      <div className="firstChild"  style={style}>
        <div>
          <Logo downloadURL={props.downloadURL} />
          <TableOfItems style={style} info={paymentIntent.data} />
        </div>
        <FintesaTerms color={style.color} />
      </div>}
    </div>
  );
}

export default Information;