import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import AxiosInstanceUserServer from "../axios/AxiosInstanceUserServer";
import { Spinner } from "react-bootstrap";
import { getDownloadURL , ref } from "firebase/storage";
import { storage } from "../../firebase";
import "./paymentIntent.css";
const PaymentCompleted = (props) => {
  const { payment_uuid, product_id, price_id, quantity } = useParams();
  const [loading, setLoading] = useState(false);
  const [account_id, setAccountId] = useState("");
  const [price, setPrice] = useState("");
  const [product, setProduct] = useState("");
  const [display_name, setdisplay_name] = useState("");
  const [backgroundColor , setBackgroundColor ] = useState('#5469d4')
  const [textColor , setTextColor ] = useState('white')

  const [ logo , setLogo ] = useState('')

  const specialCurrencies= ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga', 'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf']

  const extract_merchant_id_from_merchant_payment_uuid = async () => {
    try {
      let res = await AxiosInstanceMENACartServer.get(
        `/get_merchent_id/${payment_uuid}`
      );
      setAccountId(res.data);
    } catch (error) {
      // To be Change by aws To Message (Toast)
      // console.log(error);
    }
    
  };
  let price_value=0
  let currency=''
  if(price.metadata && price.metadata.currency_type==="jod") {
    let factor = 0.708;
    if (payment_uuid === "gZ0v_d5f7188a-de2e-4dec-a33f-9497f65a9a39") {
      factor = 0.731;
    }
    price_value= (price["unit_amount"]*factor)/100
    currency='jod'
  }
  else if (specialCurrencies.includes(price["currency"])) {
    price_value= price["unit_amount"]
    currency=price["currency"]
  }
  else {
    price_value= price["unit_amount"]/100
    currency=price["currency"]
  }





  useEffect(() => {
    extract_merchant_id_from_merchant_payment_uuid();
    const get_display_name = async () => {
      setLoading(true);
      try {
        await AxiosInstanceUserServer.get(
          `/merchant/get-display-name/${account_id}`
        ).then((res) => {
          setdisplay_name(res.data);
        });
      } catch (error) {
        // To be Change by aws To Message (Toast)
        // console.log(error);
      }
      setLoading(false);
    };
    async function getBranding(){
      setLoading(true);
  // try {
   await AxiosInstanceUserServer.get(`/branding/retrieve-branding/${account_id}`).then(
      success => {
        // console.log(success.data)
        setBackgroundColor(success.data.backgroundColor)
        setTextColor(success.data.textColor)
        return success.data;
      }
    ).then(data =>{
      if(data.selectedImage ){
        return getDownloadURL( ref(storage , data.selectedImage)) 
      }else{ 
        return false
      }
    }).then( url =>{
     if( url){
      setLogo(url)
     }
    }).catch((error )=>{
      // console.log(error);
     }).finally(setLoading(false))
  // } catch (error) {
  //   console.log(error);
  // }
  // setLoading(false);
  }
  const get_product = async () => {
    try {
      await AxiosInstanceMENACartServer.post(`/get-product`, {
        product_id: product_id,
        price_id: price_id,
        merchant_id: account_id,
      }).then((res) => {
        // console.log(res.data);
        setPrice(res.data.price);
        setProduct(res.data.product);
      });
    } catch (error) {
      // To be Change by aws To Message (Toast)
      // console.log(error);
    }
  };
    if(account_id){
      getBranding();
      get_product()
    get_display_name();
    }
    // get_product();
  }, [account_id]);

  
  //class to body
  document.body.classList.add("payment_intent_style");

  return (
    <>
    {
      loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ): product["id"] && price["id"] && (
      <div className="container_payments">
        <div className="background_element" style={{backgroundColor}}>
          <div className="text_payments" style={{color :textColor}}>
            <div className="header_payments_text">
            { logo &&   <img src={logo} alt="Logo" className="logo" style={{ width :'6rem' , height: '6rem' , objectFit: 'cover'}}/>}
              <p className="updated_text_payments"style={{color:textColor, fontSize: '2rem', marginTop:'1rem'}}>{product.name}</p>

              {
                          !price.recurring ? (
                          <h3 className="price_text_payments" style={{color:textColor}}>
                            {currency.toUpperCase()} {(price_value*Number(quantity||1)).toFixed(2)}
                          </h3>
                          ) : (
                        <h3 className="price_text_payments" style={{color:textColor}}>
                          {currency.toUpperCase()} {price_value.toFixed(2)}{price.recurring.interval_count > 1 ? "/ "+price.recurring.interval_count+" "+price.recurring.interval+"s": ""}{price.recurring.interval && price.recurring.interval_count == 1 ? "/ "+price.recurring.interval: ""}
                        </h3>
                          )

                        }
              {quantity > 1 && 
                <>
                  <p style={{color :textColor, marginBottom: "0"}}>{currency.toUpperCase()} {price_value.toFixed(2)} For Each Item</p>
                  <p>Qty: {quantity}</p>
                </>
              }
              <p className="desc_text_payments" style={{color :textColor}}>{product.description}</p>

              <div className="footer_payments_text" style={{color :textColor}}>
                <span>
                  Powered by{" "}
                  <a target="_blank" href="https://fintesa.co/" style={{color :textColor}}>
                    Fintesa
                  </a>
                </span>{" "}
                |{" "}
                <span className="terms_footer_payments_text" >
                  <a
                    href="https://fintesa.co/terms-and-conditions"
                    target="_blank"
                    style={{color :textColor}}
                  >
                    Terms
                  </a>
                </span>
              </div>
            </div>
          </div> 
        </div>
        <div className="container_success-checkmark_">
          <div className="success-checkmark">
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
          <div className="success_message">
            <h3>Thanks for your {!price.recurring ? "payment" : "subscription"}</h3>
            <p className="success_message_text">A payment to {display_name} will appear on your statement.</p>
            <div className="card_success_message">
              <span className="success_message_display_name">{display_name}</span>
             {quantity !== "undefined" ? 
             <span>
                {currency.toUpperCase()} {(price_value*Number(quantity)).toFixed(2)}
              </span> : 
              <span>
                {currency.toUpperCase()} {(price_value).toFixed(2)}
              </span>}
            </div>
          </div>
        </div>
      </div>
      ) 
    }
    </>
  );
};
export default PaymentCompleted;
