import React, { useEffect, useState } from 'react';
import styles from "./IntegrationPayForm.module.css";
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useStripe, useElements, PaymentElement, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { useParams } from 'react-router-dom';

const IntegrationPayForm = (props) => {
  const {productId, priceId} = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState(props.email ? props.email : "");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("")
  const [nameOnCard, setNameOnCard] = useState("");

  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (!props.clientSecret) {
      return;
    }
    stripe
      .retrievePaymentIntent(props.clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
        }
      });
  }, [stripe]);

  const paymentElementOptions = {
    layout: "tabs",
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    setMessage("");
    const return_url = `${process.env.REACT_APP_FRONTEND_URL_PAYMENT}/payment-success/integrations/${productId}/${priceId}/${props.quantity? props.quantity : 1}`;
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url,
        payment_method_data: {
          billing_details: {
            name: nameOnCard,
            email: email,
          },
        },
      }
    }).then((result) => {
      console.error(result);
      if (result) {
        switch (result.error.decline_code) {
          case "insufficient_funds": {
            setMessage("Insufficient funds decline");
            break;
          }
          default:
            setMessage("Your card has been declined.");
        }
      } else {

      }
    })
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);

  }
  

  return (
    <div className={styles.container}>
      <Form onSubmit={submitHandler}>
      <Form.Label
          htmlFor="email"
          style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
        >
          Email
        </Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            id="email"
            aria-describedby="basic-addon3"
            style={{ color: "rgb(33,37,41)" }}
            required
            value={email ? email : ""}
            disabled={props.email ? true : false}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </InputGroup>
        <Form.Label
          htmlFor="card-name"
          style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
        >
          Name on card
        </Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            id="card-name"
            aria-describedby="basic-addon3"
            style={{ color: "rgb(33,37,41)" }}
            required
            onChange={(e) => {
              setNameOnCard(e.target.value);
            }}
          />
        </InputGroup>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <div className={styles["button-container"]}>
          <Button
            type='submit'
            disabled={
              !stripe 
              || !elements 
              || isLoading
            }
          >Pay</Button>
        </div>
        {message &&
        <div>
          <p className={styles["error-message"]}>{message}</p>
        </div>}
      </Form>
    </div>
  )
}

export default IntegrationPayForm 