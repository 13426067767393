import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { SubscriptionPaymentPaymentForm } from "./SubscriptionPaymentPaymentForm";
const SubscriptionPaymentContainer = (props) => {
    const { clientSecret , accountId} = props
    async function LoadStripe(){
        if(props && accountId){
        let stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
         return await loadStripe(stripe_publish_key, { stripeAccount: accountId});
    }
      }
      const stripePromise = LoadStripe();
      
    return ( <>
    <Elements stripe={stripePromise} options={{clientSecret:clientSecret}} >
              <SubscriptionPaymentPaymentForm />
              </Elements>
    </> );
}
 
export default SubscriptionPaymentContainer;