import AxiosInstanceMENACartServer from "../../components/axios/AxiosInstanceMENACartServer"

export const getPaymentInfo = async (mainURL, priceId, productId, merchant_id=null) => {
  try {
    let res = await AxiosInstanceMENACartServer.post(`${mainURL}`, {
      product_id: productId,
      price_id: priceId,
      quantity: 1,
      merchant_id: merchant_id,
    })
    return res.data;
  } catch (e) {
    console.error(e)
    return {
      error: "could not get info!"
    }
  }

}

export const getProductAndPrice = async (priceId, productId,merchant_id=null) => {
  try {
    let res = await AxiosInstanceMENACartServer.post("/get-product", {
      product_id: productId,
      price_id: priceId,
      merchant_id: merchant_id,
    })
    return res.data;
    
  } catch (e) {
    console.error(e)
    return {
      error: "could not retrieve product and price data"
    }
  }
}

export const modifyPaymentIntent = async (paymentIntentId, quantity, priceId, merchant_id=null) => {
  try {
    let res = await AxiosInstanceMENACartServer.post("/modify-payment-intent", {
      PI_id: paymentIntentId,
      quantity: quantity,
      price_id: priceId,
      merchant_id: merchant_id,
    })
    return res;
  } catch (error) {
    console.error(error);
    return {
      error: "could not update quantity"
    }
  }
}