import React, { useEffect, useState } from "react";
import "./ApiIntegration.css";

import { useParams } from "react-router-dom";
import { useBrandingAndTerms } from "./useBrandingAndTerms";
import Information from "./Information";
import { Spinner } from "react-bootstrap";
import ApiPayment from "./ApiPayment";
import ApiPaymentConfiremed from "./ApiPaymentConfirmed";

const ApiIntegration = () => {
  const {merchant_uuid, client_secret} = useParams();
  const [isLoading, setLoading] = useState(false);
  const [intent, setIntent] = useState(null)
  const BrandingAndTerms = useBrandingAndTerms(merchant_uuid, setLoading);
  const [paymentIntent, setPaymentIntent] = useState("");
  useEffect(() => {
    let decryptedArray = client_secret.split("_secret")
    setPaymentIntent(decryptedArray[0]);
  },[])

  return (
    isLoading 
    ? <div className="integration-spinner">
      <Spinner />
    </div>
    :<div className="integration-parent">
      <Information setIntent={setIntent} intent={intent} uuid={merchant_uuid} intentID={paymentIntent} branding={BrandingAndTerms.branding} downloadURL={BrandingAndTerms.downLoadURL} />
      {intent && intent.data.status === "succeeded" ?
        <ApiPaymentConfiremed fromBack={true} intent={intent.data} account={BrandingAndTerms.accountId} /> 
        :<ApiPayment merchant_uuid={merchant_uuid} withShipping={intent?.data?.metadata?.with_shipping} success_url={intent?.data?.metadata?.success_url} terms={BrandingAndTerms.termAndConditions} branding={BrandingAndTerms.branding} account={BrandingAndTerms.accountId} cs={client_secret} />
      }
    </div>
  );
}

export default ApiIntegration;