import React from 'react';
import styles from "./ErrorMessage.module.css";

const ErrorMessage = ({ title, message }) => {
  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <h1>{title}</h1>
        <p>{message}</p>
      </div>
    </main>
  )
}

export default ErrorMessage