import React, { useEffect, useState } from 'react'
import Fintesa from "../../images/Fintesa.png";
import AxiosInstanceUserServer from '../axios/AxiosInstanceUserServer';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import PayFormSkeleton from '../skeletons/PayFormSkeleton';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FintesaPlansCheckoutForm } from './FintesaPlansCheckoutForm';
import PlansSubscription from '../modals/PlansSubscription';
export const FintesaLogo = (props) => {
  const {productId,priceId} = useParams();
  const [loading, setLoading] = useState(true)
  const [price, setPrice] = useState('')
  const [productName, setProductName] = useState('')
  const [clientSecret, setclientSecret] = useState('')
  const [amount, setAmount] = useState(0)
  const [currency, setCurrency] = useState('')
  const [Description, setDescription] = useState('')
  const [newCustomer, setNewCustomer] = useState(false)
  const [activeSubscription, setActiveSubscription] = useState(true)
  const [activeSubscriptionName, setActiveSubscriptionName] = useState('')
  const [activeSubscriptionId, setactiveSubscriptionId] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [show, setShow] = useState(false)
  async function LoadStripe(){
    let stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
     return await loadStripe(stripe_publish_key);
  }
  const stripePromise = LoadStripe();
  useEffect(()=>{
    AxiosInstanceUserServer.post( '/plan/target-plan', {price_id:priceId, product_id:productId}).then( res => {
      if(res.data.price){
        if( res.data.price && res.data.price.unit_amount && res.data.price.currency && res.data.price.recurring && res.data.price.recurring.interval){
         setPrice(`$${(res.data.price.unit_amount/100).toFixed(2)} / ${res.data.price.recurring.interval}`)
         setAmount(res.data.price.unit_amount)
         setCurrency(res.data.price.currency)
        }
        if( res.data.product_name ){
         setProductName(res.data.product_name)
        }
        if( res.data.client_secret){
         setclientSecret( res.data.client_secret )
        }
        if( res.data['product_description']){
          setDescription(res.data['product_description'])
        }
       }
       return  AxiosInstanceUserServer.post(`/plan/check-customer` , {email: props.email, name:props.name})
    }).then( async res => {
      if( res.data && res.data.customer_id && res.data.message === "old_customer" ){
        setCustomerId( res.data.customer_id)
        return {res : await AxiosInstanceUserServer.get(`/plan/check-subscription-plane/${res.data.customer_id}`),
        customerId:res.data.customer_id}
      }else {
        setNewCustomer( true )
        return {case : true,
                customerId : res.data.customer_id}
      }
    }).then( res => {
      if( res.res && res.res.data && res.res.data['Subscriptions Plans'] && res.res.data['Subscriptions Plans'].length > 0 && res.res.data['Subscriptions Plans'][0]['Subscription Plan ID'] && res.res.data['Subscriptions Plans'][0]['Subscription Plan']){
        setactiveSubscriptionId( res.res.data['Subscriptions Plans'][0]['Subscription Plan ID'])
        setActiveSubscriptionName(res.res.data['Subscriptions Plans'][0]['Subscription Plan'])
        setActiveSubscription(true)
        //console.log( 'from true (Customer Have Active Subscription)')
        setShow( true )
        return (false)
      }else{
        //console.log( 'from false customer do not have active subscription' )
        return AxiosInstanceUserServer.post(`/plan/create-subscription/${res.customerId }`,{product_id: productId , price_id: priceId,cancel_prev_subscription_at:'immediately', prev_subscription:activeSubscriptionId })
      }
    }).then( res => {
      if( res.data.client_secret ){
        // set Client secret From the result
        setclientSecret( res.data.client_secret)
      }
    }).catch( error => {
      console.error( error )
    }).finally(()=>{
      setLoading(false)
    })
  },[])
  // AxiosInstanceUserServer.post(`/plan/create-subscription/testId`,{product_id:productId , price_id:priceId}).catch( error => {
  //   console.error(error)
  // })
  return (
    <>

  {loading && <div style={{width:'100%', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <Spinner animation='border' variant='primary'/>
   </div>}
   {!loading &&  <div style={{display:'flex', alignItems:'center', padding:'3rem', flexDirection:'column',gap:'1rem',position:'relative', width:'100%'}}>
        <div style={{maxWidth:'6rem', height:'auto'}}>
            <img src={Fintesa} style={{maxWidth:'100%'}}/>
        </div>
        <h3>{productName}</h3>
        <h3>{price}</h3>
        <p style={{marginTop:'2rem', padding:'1rem 2rem', textAlign: "center"}}>
            {Description && Description}
        </p>
        <div style={{position:'absolute',bottom:'2rem'}}><p>
        <span>
            Powered by <a target="_blank" href="https://fintesa.co/" style={{color:'black', textDecoration:'none'}} >Fintesa </a>
          </span>
          |
          <span className="terms_footer_payments_text" >
            <a href="https://fintesa.co/terms-and-conditions" target="_blank" style={{color:'black', textDecoration:'none'}} > Terms</a>
          </span>
        </p></div>
    </div>}
          <PlansSubscription activeSubscriptionId={activeSubscriptionId} activeSubscriptionName={activeSubscriptionName} show={show} setclientSecret={setclientSecret} customerId={customerId}/>
        {clientSecret?  <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'white'}}>
        <Elements options={{clientSecret:clientSecret}} stripe={stripePromise} customerId={customerId}>
          <FintesaPlansCheckoutForm email={props.email} price={amount} productName={productName} currency={currency} />
        </Elements>
        </div>:<div style={{display:'flex', alignContent:'center',justifyContent:'center', alignItems:'center', width:'100%'}}> <PayFormSkeleton /> </div>}
        
    </>
  )
}
