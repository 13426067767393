import React from 'react'
import styles from "./IntegrationPayment.module.css";


const PaymentSuccess = ({priceObject, quantity}) => {
  return (
    <div className={styles.container}>
      <div>
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </div>
        <div className="success_message">
          <h3>
            Thanks for your Subscription
          </h3>
          <p className="success_message_text">
            A payment to Fintesa will appear on your statement.
          </p>
          <div className="card_success_message">
            <span className="success_message_display_name">Fintesa</span>
            {priceObject && <span>
              ${((priceObject.unit_amount*quantity)/100).toFixed(2)}
            </span>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess