import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import CheckoutForm from "./CheckoutForm";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";

export default function PaymentIntent(props) {
  const [clientSecret, setClientSecret] = useState("");
  const [account_id, setAccountId] = useState("");
  let { payment_uuid, product_id, price_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const param = useParams();
  const [quantity, setQuantity] = useState(1);

 

  const createPaymentIntent = async (quantity) => {
    setUpdateForm(true);
    if(account_id){

    try {
      let res = await AxiosInstanceMENACartServer.post(
        `/create-payment-intent`,
        { product_id, quantity, price_id, merchant_id: account_id }
      );
     
      setClientSecret(res.data.clientSecret);
    } catch (error) {
      // To be Change by aws To Message (Toast)
      // console.log(error);
    }
    setUpdateForm(false);}
  };
  
  
  async function LoadStripe(){
    let stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
     return await loadStripe(stripe_publish_key, {
      stripeAccount: account_id,
    });
  }
  const stripePromise = LoadStripe();
  useEffect(() => {
     // Send Request to Create a Payment Link for the Product
  const createPaymentIntent = async () => {
    setIsLoading(true);
    if(account_id){
    try {
      let res = await AxiosInstanceMENACartServer.post(
        `/create-payment-intent`,
        { product_id, quantity, price_id, merchant_id: account_id }
      );
  
      setClientSecret(res.data.clientSecret);
    } catch (error) {
      // To be Change by aws To Message (Toast)
      // console.log(error);
    }
    setIsLoading(false);}
  };
    const extract_merchant_id_from_merchant_payment_uuid = async () => {
      setIsLoading(true);
      if (payment_uuid) {
        
        try {
          let res = await AxiosInstanceMENACartServer.get(
            `/get_merchent_id/${payment_uuid}`
          );
          setAccountId(res.data);
        createPaymentIntent();
        } catch (error) {
          // To be Change by aws To Message (Toast)
          // console.log(error);
        }
      }
      setIsLoading(false);
    };
    extract_merchant_id_from_merchant_payment_uuid();
  }, [account_id]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const payment_info = {
    account_id: account_id,
    product_id: product_id,
    price_id: price_id,
  };
  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        clientSecret && (
            <CheckoutForm
              options={options}
              stripePromise={stripePromise}
              clientSecret={clientSecret}
              payment_info={payment_info}
              account_id={account_id}
              payment_uuid={payment_uuid}
              quantity={quantity}
              setQuantity={setQuantity}
              createPaymentIntent={createPaymentIntent}
              updateForm={updateForm}
            />
        )
      )}
    </div>
  );
}

