import { LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react'

export const SubscriptionPaymentPaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const {error} = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: "https://google.com",
          },
        });
    
        if (error) {
          // handle error
        }
      };
  return (<>
   <form onSubmit={handleSubmit}>
      <h3>Contact info</h3>
      <LinkAuthenticationElement />
      {/* If collecting shipping */}
      <h3>Payment</h3>
      <PaymentElement />

      <button type="submit">Submit</button>
    </form>
  </>)
}
