import React, { useState,useEffect } from 'react';
import PaymentIntent from './PaymentIntent';
import CustomerRegister from './CustomerRegister';
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import { useParams } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
function Pay(props) {
    let { payment_uuid,product_id,price_id } = useParams();
    const [account_id, setAccountId] = useState("");
    const [isRecurring,setIsRecurring] = useState(false);
    const [isLoading, setIsLoading] = useState(false);



    
    useEffect(() => {
        const extract_merchant_id_from_merchant_payment_uuid = async () => {
            setIsLoading(true);
            try {
              let res = await AxiosInstanceMENACartServer.get(
                `/get_merchent_id/${payment_uuid}`,
                
              );
              setAccountId(res.data);
              
            } catch (error) {
              // To be Change by aws To Message (Toast)
              // console.log(error);
            }finally{
                
            }
          };
        const get_product = async () => {
            try {
              await AxiosInstanceMENACartServer.post(`/get-product`, {
                product_id: product_id,
                price_id: price_id,
                merchant_id: account_id,
              }).then((res) => {
                  if (res.data.price['type'] == 'recurring'){
                      setIsRecurring(true)
                    }
                    else{
                        setIsRecurring(false)
                    }
                });
                setIsLoading(false);
            } catch (error) {
              // To be Change by aws To Message (Toast)
              // console.log(error);
            }
          };
    
    extract_merchant_id_from_merchant_payment_uuid();
    if( account_id ){

      get_product();
    }
    }, [account_id]);


    return (
        <>
        { isLoading ?     <div style={{width: '100vw', height: '100vh' , display: 'flex' , alignItems: 'center', justifyContent: 'center'}}>
          <Spinner
          animation='border'
          role='status'
          variant='primary'
          style={{ marginBottom: '3%' }}>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
        </div>:(
        <> {isRecurring? <CustomerRegister account_id = {account_id}/>:<PaymentIntent/>}</>)
        }
            
        </>
    );
}

export default Pay;