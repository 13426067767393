
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

function CurrencySubscritptionError() {
  useEffect(() => {
    clearCacheData();
  }, []);

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };



  return (
        <div style={{width: '100vw', maxWidth: '100vw', height: '100vh', maxHeight: '100vh', display: 'flex', alignItems: 'center' , justifyContent: 'center' , flexDirection:'column'}}>

      <Row>
        <h1 style={{ marginBottom: '3%' }}>You can't pay using different currencies</h1>
      </Row>
        <Row style={{marginTop: '5rem'}}>
        <Spinner
          animation='border'
          role='status'
          variant='primary'
          style={{ marginBottom: '3%' }}>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
        </Row>
              </div>
  );
}

export default CurrencySubscritptionError;