import React from "react";

const TermsAndConditions = (props) => {
  const handleCheckboxValue = () => {
    props.setIsChecked(!props.isChecked);
  }

  return (
    <div className="termsconditions_checkbox">
      <input type="checkbox" onChange={handleCheckboxValue} />
      <label>
        Accept{" "}
        <a href={props.terms} target="_blank">
          Terms & Conditions
        </a>
      </label>
    </div>
  );
};

export default TermsAndConditions;
