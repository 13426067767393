import React from "react";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton"

const style = {
    
}

const PayFormSkeleton = () => {
    return (
        <div
            style={{
                height:"100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{
                // minHeight: "70%",
                padding: "40px",
                flexDirection: "column",
                width: "clamp(260px, 400px, 700px)",
                border: "2px solid #ebedf6ec"
            }}>
                <SkeletonTheme>
                    <p>
                        <Skeleton width={"70%"} height={"3em"}/>
                        <br/><Skeleton height={"3em"}/>
                        <br/><Skeleton height={"3em"}/>
                        <br/><Skeleton height={"3em"}/>
                        <br/><Skeleton height={"3em"}/>
                        <br/><Skeleton height={"3em"}/>
                        <br/><Skeleton height={"3em"}/>
                    </p>
                </SkeletonTheme>
            </div>
        </div>
    );
}

export default PayFormSkeleton;