/** @format */

import { Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

function InternalServerErrorPage() {
  useEffect(() => {
    clearCacheData();
  }, []);

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  return (
    <Row
      style={{
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: '20%',
        marginTop: '10%',
      }}>
      <Row>
        <h1 style={{ marginBottom: '3%' }}>500 Internal Server Error</h1>
        <h5 style={{ marginBottom: '3%' }}>
          It seems that there is an Internal Server Error
        </h5>
        <Spinner
          animation='border'
          role='status'
          variant='primary'
          style={{ marginLeft: '50%', marginBottom: '3%' }}>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </Row>

      <Row>
        <p>
          Try Again or Go to <a href='/'>Home page</a>
        </p>
      </Row>
    </Row>
  );
}
export default React.memo(InternalServerErrorPage);
