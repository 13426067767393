import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const specialCurrencies= ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga', 'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf']

const SubscriptionDetails = (props) => {
  const [factor, setFactor] = useState(100);
  const [currency, setCurrency] = useState("usd");
  const {merchant_uuid} = useParams();

  useEffect(() => {
    if (props.info.metadata.currency_type==="jod") {
      let jodFactor = 0.708;
      if (merchant_uuid === "gZ0v_d5f7188a-de2e-4dec-a33f-9497f65a9a39") {
        jodFactor = 0.731;
      }
      setFactor(100/jodFactor)
      setCurrency("JOD");
    } else if (specialCurrencies.includes(props.info.currency)) {
      setFactor(1);
      setCurrency(props.info.currency.toUpperCase());
    } else {
      setCurrency(props.info.currency.toUpperCase());
    }
  }, []);
  return (
    <div className="subscription-details">
      <h3>{ props.info.metadata.item_name ? props.info.metadata.item_name : "Item" }</h3>
      <p>
        {currency.toUpperCase()} {((props.info.amount)/factor).toFixed(2)}
        {props.info.metadata.interval_count > 1 ? "/ "+props.info.metadata.interval_count+" "+props.info.metadata.interval+"s": ""}{props.info.metadata.interval && props.info.metadata.interval_count == 1 ? "/ "+props.info.metadata.interval: ""}
      </p>
      {props.info.metadata.description ? <p>{props.info.metadata.description}</p> : <></>}
    </div>
  )
}

export default SubscriptionDetails