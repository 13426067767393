import { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AxiosInstanceUserServer from '../axios/AxiosInstanceUserServer';
import { useParams } from 'react-router-dom';

function PlansSubscription(props) {
  const [show, setShow] = useState(false);

  const {productId,priceId} = useParams();
  const [cancelAt, setCancelAt] = useState('immediately')
    useEffect( ()=> {
        setShow( props.show )
    } , [props.show])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading( true )
    AxiosInstanceUserServer.post(`/plan/create-subscription/${props.customerId}`,{product_id: productId , price_id: priceId,cancel_prev_subscription_at:cancelAt, prev_subscription:props.activeSubscriptionId }).then( res => {
      props.setclientSecret( res.data.client_secret)
      handleClose();
    }).catch( error => {
      console.error( error )
    })
  }
    const [loading, setLoading] = useState(false)
    const handleChange = e => {
        e.persist();
        setCancelAt(e.target.value);
      };
  return (
    <>
      <Modal show={show}>
        <Modal.Header >
          <Modal.Title>Fintesa plans</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>

        <Modal.Body>
            <Form.Label>You already have active subscription in {props.activeSubscriptionName}, please contact with us to change your plan</Form.Label>
            {/* <Form.Group controlId="cancelAt">
        <Form.Check
          value="immediately"
          type="radio"
          aria-label="radio 1"
          label="Immediately"
          onChange={handleChange}
          checked={cancelAt === "immediately"}
        />
        <Form.Check
          value="end_current_period"
          type="radio"
          aria-label="radio 2"
          label="At the end of current subscription"
          onChange={handleChange}
          checked={cancelAt === "end_current_period"}
        />
      </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <a style={{ textDecoration:'none',backgroundColor:'rgb(13, 110, 253)',border:'1px solid rgb(13, 110, 253)', borderRadius:'6px', padding:'0.5rem 1rem', color:'white', cursor:'pointer'}} href={`${process.env.REACT_APP_FRONTEND_URL_FINTESA_WEBSITE}#contactus`} target="_blank">Contact</a>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default PlansSubscription;