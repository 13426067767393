import React, { useEffect, useState } from "react";
import AxiosInstanceMENACartServer from "../../components/axios/AxiosInstanceMENACartServer";

const useIntentData = (intentID, uuid) => {
  const [intentObject, setIntentObject] = useState();
  useEffect(() => {
    if(!intentID) return;
    AxiosInstanceMENACartServer.post(`/payment-intent/retrieve/${uuid}`,{
      payment_intent_id: intentID,
    }).then((res) => {
      setIntentObject(res);
    }).catch(e => {
      console.error(e);
    })
  }, [])

  return intentObject;
}

export default useIntentData;