/** @format */

// https://www.npmjs.com/package/@paypal/react-paypal-js
// https://developer.paypal.com/sdk/js/configuration

import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

export default function PaypalIntegrationButton() {
  return (
    <PayPalScriptProvider
      options={{
        'client-id':
          'ASppiiLHQCqNBa7ILRBk7WsWzbgfAMF2XE3WmunBSPMhp-M_uFBqgUQqCwK9XPJcVCH-pAZd0v7UU-gV',
      }}>
      <PayPalButtons
        style={{ layout: 'horizontal', width: '100%', padding: '0' }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '13.99',
                },
                custom_id: 'my custom string', // https://www.paypal-community.com/t5/REST-APIs/How-do-I-add-custom-metadata-to-PayPal-so-it-can-be-sent-with/td-p/2039694
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          const details = await actions.order.capture();
          const name = details.payer.name.given_name;
          alert('Transaction completed by ' + name);
        }}
      />
    </PayPalScriptProvider>
  );
}
