import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { Alert, Form, InputGroup, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PhoneNumberInput from "../inputs/PhoneNumberInput";

const PayForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  
  const {
    setIsLoading,
    email: initialEmail,
    clientSecret,
    currency,
    productName,
    quantity,
    price,
    success_url,
    payment_uuid,
    payment_info,
    isLoading,
    buttonBackgroundColor,
    buttonTextColor,
    termsAndConditions,
    isChecked,
    setIsChecked,
    termsAndConditionsError,
  } = props;

  const [cardName, setCardName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(null);
  const [email, setEmail] = useState(initialEmail || "");
  const [message, setMessage] = useState("");
  const [paymentRequest, setPaymentRequest] = useState(null);

  // Retrieve Payment Intent status
  useEffect(() => {
    if (!stripe || !clientSecret) return;

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        default:
          setMessage("");
      }
    });
  }, [stripe, clientSecret]);

  // Set up Payment Request Button
  useEffect(() => {
    if (!stripe) return;

    const pr = stripe.paymentRequest({
      country: "US",
      currency: currency,
      total: {
        label: productName,
        amount: quantity ? price.unit_amount * quantity : price.unit_amount,
      },
      displayItems: [
        {
          label: `${productName} ${quantity ? `* ${quantity}` : ""}`,
          amount: quantity ? price.unit_amount * quantity : price.unit_amount,
        },
      ],
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((result) => {
      if (result) setPaymentRequest(pr);
    });

    pr.on("paymentmethod", async (ev) => {
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        ev.complete("fail");
      } else {
        ev.complete("success");
        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            setMessage("The payment failed, please try again.");
          } else {
            navigate(
              `/success_url/${payment_uuid}/${payment_info["product_id"]}/${payment_info["price_id"]}/${quantity}`
            );
          }
        } else {
          navigate(
            `/success_url/${payment_uuid}/${payment_info["product_id"]}/${payment_info["price_id"]}/${quantity}`
          );
        }
      }
    });
  }, [
    stripe,
    clientSecret,
    currency,
    productName,
    price.unit_amount,
    quantity,
    payment_uuid,
    payment_info,
    navigate,
  ]);

  // Validate phone number
  useEffect(() => {
    const phoneRegex =
      /^(\s*|\+\d{1,3}?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4})$/;
    setIsPhoneNumberValid(phoneRegex.test(phoneNumber));
  }, [phoneNumber]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!stripe || !elements) return;

    if (!email) {
      setMessage("Please enter a valid email address.");
      return;
    }
    if (!isPhoneNumberValid) {
      setMessage("Please enter a valid phone number.");
      return;
    }
    if (!cardName) {
      setMessage("Please enter the name on the card.");
      return;
    }

    setIsLoading(true);

    const return_url =
      success_url ||
      `${process.env.REACT_APP_FRONTEND_URL_PAYMENT}/success_url/${payment_uuid}/${payment_info["product_id"]}/${payment_info["price_id"]}/${quantity}`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: return_url,
        payment_method_data: {
          billing_details: {
            name: cardName,
            email: email,
            phone: phoneNumber,
          },
        },
      },
    });

    if (error) {
      setMessage(error.message);
      setIsLoading(false);
    }
  };

  // Handle checkbox value change
  const handleCheckboxValue = (e) => {
    setIsChecked(e.target.checked);
  };

  const paymentElementOptions = { layout: "tabs" };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        id="payment-form"
        style={{ border: message ? "1px solid red" : "none" }}
      >
        <Form.Label
          htmlFor="email"
          style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
        >
          Email
        </Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            id="email"
            aria-describedby="basic-addon3"
            style={{ color: "rgb(33,37,41)" }}
            required
            value={email}
            disabled={!!initialEmail}
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputGroup>
        <PhoneNumberInput
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          payment_uuid={payment_uuid}
          isValid={isPhoneNumberValid}
        />
        <Form.Label
          htmlFor="card-name"
          style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
        >
          Name on card
        </Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            id="card-name"
            aria-describedby="basic-addon3"
            style={{ color: "rgb(33,37,41)" }}
            value={cardName}
            required
            onChange={(e) => setCardName(e.target.value)}
          />
        </InputGroup>
        <PaymentElement
          id="payment-element"
          options={paymentElementOptions}
        />
        <button
          disabled={
            isLoading ||
            !stripe ||
            !elements ||
            !isChecked ||
            termsAndConditionsError ||
            !isPhoneNumberValid
          }
          id="payment-submit"
          style={{
            backgroundColor: buttonBackgroundColor,
            color: buttonTextColor,
          }}
        >
          <span id="button-text">
            {isLoading ? (
              <Spinner
                animation="border"
                style={{ color: buttonTextColor }}
              />
            ) : (
              `${!price.recurring ? "Pay now" : "Subscribe"}`
            )}
          </span>
        </button>
        {paymentRequest && <hr />}
        {paymentRequest && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )}
        {termsAndConditions && (
          <div className="termsconditions_checkbox">
            <input type="checkbox" onChange={handleCheckboxValue} />
            <label>
              Accept{" "}
              <a href={termsAndConditions} target="_blank" rel="noreferrer">
                Terms & Conditions
              </a>
            </label>
          </div>
        )}
      </form>
      {message && (
        <Alert
          variant="danger"
          className="alert-message"
          style={{ color: "red", marginTop: "1rem", textAlign: "center" }}
          id="payment-message"
        >
          {message}
        </Alert>
      )}
    </>
  );
};

export default PayForm;
