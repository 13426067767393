import React, { useEffect, useState } from 'react'
import PageContainer from '../components/UI/PageContainer'
import SectionContainer from '../components/UI/SectionContainer'
import SubscriptionInfo from '../components/IntegrationInformation/SubscriptionInfo'
import { useParams } from 'react-router-dom'
import {getPaymentInfo, getProductAndPrice, modifyPaymentIntent} from "../common/services/getPaymentInfo"
import { Spinner } from 'react-bootstrap'
import IntegrationPayment from '../components/IntegrationInformation/IntegrationPayment'
import QuantityModal from '../components/modals/QuantityModal'

const OneTimePaymentFintesa = () => {
  const mainURL = "/create-payment-intent";
  const {productId, priceId} = useParams();
  const [clientSecret, setClientSecret] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [priceObject, setPriceObject] = useState(null);
  const [showQuantityModal, setShowQuantityModal] = useState(false);
  const [quantity, setQuantity] = useState(1);
  useEffect(() => {
    getData();
  }, [])
  const getData = async () => {
    const intentData = await getPaymentInfo(mainURL, priceId, productId, null);
    const productAndPrice = await getProductAndPrice(priceId, productId);
    if (intentData && intentData.clientSecret) {
      setClientSecret(intentData.clientSecret)
    }
    if (productAndPrice && productAndPrice.price && productAndPrice.price.id) {
      setPriceObject(productAndPrice.price);
      setProductName(productAndPrice.product.name);
      setProductDescription(productAndPrice.product.description);
    }
  }
  const updateQuantity = async (quantityValue) => {
    if (!clientSecret || !priceId) return;
    let paymentIntentId = clientSecret.split("_secret")[0];
    let data = await modifyPaymentIntent(paymentIntentId, quantityValue, priceId, null)
    if (data && data.clientSecret) {
    }
  }
  
  if (!productId || !priceId || !clientSecret || !priceObject ) {
    return <div style={{display: 'flex', alignItems: "center", justifyContent: 'center', height: "100vh"}}>
      <Spinner animation='border' size='lg' />
    </div>
  }
  return (
    <PageContainer>
      {showQuantityModal && <QuantityModal updatePaymentIntent={updateQuantity} show={showQuantityModal} setShow={setShowQuantityModal} quantity={quantity} setQuantity={setQuantity} />}
      <SectionContainer >
        <SubscriptionInfo quantity={quantity} productName={productName} priceObject={priceObject} productDescription={productDescription} showQuantityModal={setShowQuantityModal} setPriceObject={setPriceObject} />
      </SectionContainer>
      <SectionContainer>
        <IntegrationPayment clientSecret={clientSecret} qty={quantity} />
      </SectionContainer>
    </PageContainer>
  )
}

export default OneTimePaymentFintesa