// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_API_KEY_Firebase}`,
  authDomain: `${process.env.REACT_APP_OUTH_DOMAIN_Firebase}`,
  projectId: `${process.env.REACT_APP_PROJECT_ID_Firebase}`,
  storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET_Firebase}`,
  messagingSenderId: `${process.env.REACT_APP_MESSAGIN_SENDER_ID_Firebase}`,
  appId: `${process.env.REACT_APP_AOO_ID_Firebase}`,
  measurementId: `${process.env.REACT_APP_MEASUREMENT_ID_Firebase}`,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);
