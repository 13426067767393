import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import { Toast } from "react-bootstrap";
import ErrorMessage from "./ErrorMessage";

export default function CreateSubscription(props) {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [account_id, setAccountId] = useState("");
  let { payment_uuid, product_id, price_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState(props.customerId);
  const [toastError, setToastError ] = useState(false)
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false);

  // console.log('from CreateSubscription');
  const param = useParams();

  const extract_merchant_id_from_merchant_payment_uuid = async () => {
    setIsLoading(true);
    try {
      let res = await AxiosInstanceMENACartServer.get(
        `/get_merchent_id/${payment_uuid}`
      );
      setAccountId(res.data);
      if (!props.customerId){
        createCustomer();
      }
      else{
        check_if_already_subscriped()
        
      }
    } catch (error) {
      // To be Change by aws To Message (Toast)
      // console.log(error);
    }
    setIsLoading(false);
  };


  let stripe_publish_key = process.env.REACT_APP_PUBLISHABLE_KEY;
  const stripePromise = loadStripe(stripe_publish_key, {
    stripeAccount: account_id,
  });


  const createCustomer= async()=>{

    setIsLoading(true);
    if (account_id) {
      try {
        let res = await AxiosInstanceMENACartServer.post(
          `/customer/create/${account_id}`,
          { email:props.email,
            name:props.name }
        ).then(res=>{
          setCustomerId(res.data)
          createPaymentSubscription(res.data);
        });
  
      } catch (error) {
    // To be Change by aws To Message (Toast)
        // console.log(error);
      }
    }
    setIsLoading(false);
  };



  // Send Request to Create a Payment Link for the Product
  const check_if_already_subscriped = async () => {
    setIsLoading(true);
    try {
      if(account_id){
        await AxiosInstanceMENACartServer.post(
          `/subscription/retrieve/${account_id}`,
          { product_id:product_id,customer_id:props.customerId }
           ).then(res=>{
             if (!(res.data.data &&  res.data.data.length > 0)){
                 createPaymentSubscription(props.customerId);
             }
             else{
              navigate('/notFound')
             }
           })
      }
      // setClientSecret(res.data);
    } catch (error) {
  // To be Change by aws To Message (Toast)
      // console.log(error);
    }
    setIsLoading(false);
  };
  const createPaymentSubscription = async (customerId) => {
    setIsLoading(true);
    try {
      let res = await AxiosInstanceMENACartServer.post(
        `/create-payment-subscription/${account_id}`,
        { customer_id:customerId ,price_id:price_id , product_id:product_id}
      );
      // console.log(res);
      if (res.data && res.data.error) {
       setMessage(res.data.error);
       setShowError(true); 
      }
      if(res.data !=='Currency Error'){
        setClientSecret(res.data);
      }else{
        navigate('/subscriptionerror')
      }
      
    } catch (error) {
  // To be Change by aws To Message (Toast)
      // console.log(error);
      setShow(false)
      setToastError(true)
      setTimeout(() => {
        setToastError(false)
      }, 3000);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    extract_merchant_id_from_merchant_payment_uuid();
  }, [account_id]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const payment_info = {
    account_id: account_id,
    product_id: product_id,
    price_id: price_id,
  };

  if(showError) {
    return (
    <ErrorMessage
      title="An Error Occurred While Creating Subscription."
      message={message}
    />);
  }
  return (
    <div>
      {toastError &&  <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>Somthing went wrong</Toast.Body>
        </Toast>}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              clientSecret={clientSecret}
              payment_info={payment_info}
              account_id={account_id}
              payment_uuid={payment_uuid}
              name = {props.name}
              email = {props.email}
            />

          </Elements>
        )
      )}
    </div>
  );
}

