import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'

export const SubscriptionPorductData = (props) => {
    const {product, price, description} = props
    const [loading, setLoading] = useState(true)
    const [logo, setLogo] = useState('')
  return (<>
    {loading && <div style={{width:'100%', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <Spinner animation='border' variant='primary'/>
   </div>}
   {!loading &&  <div style={{display:'flex', alignItems:'center', padding:'3rem', flexDirection:'column',gap:'1rem',position:'relative', width:'100%'}}>
        {<div style={{maxWidth:'6rem', height:'auto'}}>
            <img src='' style={{maxWidth:'100%'}}/>
        </div>}
        <h3>{product}</h3>
        <h3>{price}</h3>
        <p style={{marginTop:'2rem', padding:'1rem 2rem'}}>
            {description && description}
        </p>
        <div style={{position:'absolute',bottom:'2rem'}}><p>Powered by Fintesa</p></div>
    </div>}
  </>)
}
