import React from "react";


const Logo = (props) => {
  
  return(
    props.downloadURL
    ?<div style={{}}><img src={props.downloadURL} alt="Logo" /></div>
    :<></>
  );
}

export default Logo;