import {  CardElement , Elements , PaymentElement, AddressElement} from "@stripe/react-stripe-js";
import { useStripe , useElements} from "@stripe/react-stripe-js";
import { Button, Form, Spinner } from "react-bootstrap";
import countriesData from "../json/countries.json";
import { useState } from "react";
import AxiosInstanceMENACartServer from "../axios/AxiosInstanceMENACartServer";
import { useParams } from "react-router-dom";
const countries = JSON.parse(JSON.stringify(countriesData));
const UpdatePaymentMethodForm = ( props ) => {
  const param = useParams();
    const elements = useElements()
   const [country , setCountry ] = useState('AF')
   const [error ,setError ] = useState( false )
    const stripe = useStripe(process.env.REACT_APP_PUBLISHABLE_KEY,{stripeAccount:props.accountId})
    const [loaidng , setLoading ] = useState( false )
   async function handleSubmit(e){
     e.preventDefault()
     setError(false)
    setLoading(true)
        if(!stripe || !elements){
            return;
        }
        const cardElement = elements.getElement(CardElement)
        await stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details:{
            address:{
              country:country
            }
          },
          }).then( async res => {

           return await AxiosInstanceMENACartServer.post(`/payment-method/update/${props.accountId}`, {payment_method_id:res.paymentMethod.id,subscription_id:param.subscription, customer_id:param.customer})
          }).then((res) =>{
            if(res.data === 'Payment_Method_Changed_Successfully'){
              props.setPaymentMethodUpdated( true )
            }else{
              setError(true)
            }
           
          }).catch( err => {
            setError(true)
          }).finally(()=>{
            setLoading(false)
          });
        // createPaymentMethod(cardNumber)
    }
    return ( <>
    <form onSubmit={handleSubmit}>
        <div style={{minHeight:'50px'}}>
            <CardElement options={{hidePostalCode:true ,style: {
    base: {
      iconColor: 'red',
      color: '#000',
      fontWeight: '500',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87BBFD',
      },
      'padding':'0.25rem'
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  },}}/>
        </div>
    <Form.Group className='mb-3'>
              <label>Country</label>
              <Form.Select defaultValue='AF'
              onChange={(e)=>{
                setCountry(e.target.value)
              }}>
                {countries.map((item) => (
                  <option value={item.code} key={item.name}>{item.name}</option>
                  ))}
              </Form.Select>
            </Form.Group>
                  <div style={{width:'100%', display:'flex' , alignContent:'center', justifyContent:'center'}}>
                    <Button disabled={loaidng}
                     style={{ backgroundColor :props.buttonColor, color: props.buttonTextColor , borderColor:props.buttonColor, padding:'0.5rem 2rem' ,minWidth:'118'}}
                      type='submit'>
                        {loaidng ? <Spinner animation='border' variant="light" size="sm"/> :'Update'}
                        </Button>
                        </div>

    </form>
    {error && <span style={{color:'red', textAlign:'center', width:'100%',display:'block', marginTop:'1rem'}}>Something went wrong</span>}
    </> );
}
 
export default UpdatePaymentMethodForm;