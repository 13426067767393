import React from "react";

const FintesaTerms = (props) => {
  return (
    <div className="fintesa-terms">
      <div className="footer_payments_text" style={{ color: props.color }}>
        <span>
          Powered by{" "}
          <a
            target="_blank"
            href="https://fintesa.co/"
            style={{ color: props.color }}
          >
            Fintesa
          </a>
        </span>{" "}
        |{" "}
        <span className="terms_footer_payments_text">
          <a
            target="_blank"
            href="https://fintesa.co/terms-and-conditions"
            style={{ color: props.color }}
          >
            Terms
          </a>
        </span>
      </div>
    </div>
  );
};

export default FintesaTerms;
