import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosInstanceMENACartServer from "../components/axios/AxiosInstanceMENACartServer";
import AxiosInstanceUserServer from "../components/axios/AxiosInstanceUserServer";
import { ref  , getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import UpdatePaymentMethodForm from "../components/UpdatePaymentMethod/UpdatePaymentMethodConsumer";
import { Spinner } from "react-bootstrap";
const UpdatePaymentMethod = (props) => {
    const param = useParams()
    const [loading ,setLoading ] = useState(true)
    const [accountId , setAccountId ] = useState('')
    const [backgroundColor , setBackgroundColor ] = useState('#5469d4')
    const [textColor , setTextColor ] = useState('white')
    const [buttonColor , setButtonColor ] = useState('#5469d4')
    const [buttonTextColor, setButtonTextColor] = useState('white')
    const [logo , setLogo ] = useState('')
    const [price, setPrice] = useState('')
    const [product , setProduct ] = useState('')
    const [paymentMethodUpdated , setPaymentMethodUpdated ] = useState(false)

    let factor = 0.708;
    if(param.id === "gZ0v_d5f7188a-de2e-4dec-a33f-9497f65a9a39") {
      factor = 0.731;
    }
    useEffect(()=>{
    const extract_merchant_id_from_merchant_payment_uuid = async () => {
        setLoading(true);
        
          let res = await AxiosInstanceMENACartServer.get(`/get_merchent_id/${param.id}`).then( res => {
              setAccountId(res.data);
              return res.data;
          }).then(async(id) => {
           let res = await AxiosInstanceUserServer.get(`/branding/retrieve-branding/${id}`)
           return res.data
          }).then(async res => {
            setBackgroundColor(res.backgroundColor)
            setTextColor(res.textColor)
            setButtonTextColor(res.buttonTextColor)
            setButtonColor(res.buttonColor)
            if( res.selectedImage ){
                return await getDownloadURL( ref(storage , res.selectedImage)) 
            }else{
                return false
            }
          }).then( async res => {
            if( res ){
                setLogo(res)
            }
          }).catch( error => {
            // Handle Error
          });
        
      };
    extract_merchant_id_from_merchant_payment_uuid();
}, [])
useEffect(()=>{
    async function getProduct(){
        await AxiosInstanceMENACartServer.post(
            `/get-product`,
            {
              product_id:param.productId,
              price_id: param.priceId,
              merchant_id: accountId,
            }
          ).then( (res )=>{

            setPrice(
              res.data.price.metadata.currency_type === 'jod' ?
              `${res.data.price.metadata.symbol} ${(res.data.price.unit_amount / 100* factor).toFixed(2)} per ${res.data.price.recurring.interval}`
              :
              (`${res.data.price.metadata.symbol} ${(res.data.price.unit_amount / 100).toFixed(2)} per ${res.data.price.recurring.interval}`)
              )
            setProduct(`${res.data.product.name}`)
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          })
    }
    if( accountId ){
        getProduct()
        }
},[accountId])
    return ( <>
    <div className="update-payment-method-container" style={{backgroundColor:backgroundColor, color:textColor}}>
      { loading && <div style={{width:'100%', height:'100vh', display:'flex' , placeContent:'center', alignItems:'center', backgroundColor:'white'}}>
        <Spinner animation='border' variant="primary" />
      </div>}
       { !loading && <>
       <div className="product-container">
         { logo &&  <div style={{width:'6rem' , borderRadius:'50%'}}>
                <img src={logo} style={{maxHeight:'100%', maxWidth:'100%',borderRadius:'50%'}}/>
            </div>}
            <h2 style={{ textAlign:'center'}}>{product}</h2>
            <h3 style={{ textAlign:'center'}}>{price}</h3>
        </div>
        { paymentMethodUpdated && <div style={{height:'315px',display:'flex',alignContent:'center',justifyContent:'center', flexDirection:'column', textAlign:'center'}}>
          <h2>Payment method updated successfully &#9989;</h2>
        </div> }
    { !paymentMethodUpdated &&   <div className="card-info-container simple-black-shadow" style={{marginTop:'3rem',backgroundColor:'white',color: "rgb(48,49,61)", padding:'2rem 2rem', borderRadius:'16px'}}>
            <h3 style={{marginBottom:'3rem', textAlign:'center'}}>Update payment method</h3>
{accountId && <UpdatePaymentMethodForm accountId={accountId} buttonTextColor={buttonTextColor} buttonColor={buttonColor} setPaymentMethodUpdated={setPaymentMethodUpdated}/>}
        </div>}
        </> }
    </div>
    </> );
}
 
export default UpdatePaymentMethod;