import React, { useEffect, useState } from 'react'
import PageContainer from '../components/UI/PageContainer'
import SectionContainer from '../components/UI/SectionContainer'
import SubscriptionInfo from '../components/IntegrationInformation/SubscriptionInfo'
import PaymentSuccess from '../components/IntegrationInformation/PaymentSuccess'
import AxiosInstanceMENACartServer from '../components/axios/AxiosInstanceMENACartServer'
import { useParams } from 'react-router-dom'

const IntegrationSuccess = () => {
  const {productId, priceId, qty} = useParams();
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [priceObject, setPriceObject] = useState(null);
  // todo: add a payment complete component
  const getInfo = () => {
    AxiosInstanceMENACartServer.post("/get-main-account-product", {
      product_id: productId,
      price_id: priceId,
    }).then((res) => {
      if (res.data && res.data.product) {
        setPriceObject(res.data.price);
        setProductDescription(res.data.product.description);
        setProductName(res.data.product.name);
      }
    }).catch((err) => {
      console.error(err);
    })
  }

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <PageContainer>
      <SectionContainer>
        {productName && <SubscriptionInfo quantity={qty} productName={productName} priceObject={priceObject} productDescription={productDescription} />}
      </SectionContainer>
      <SectionContainer>
        {/* <IntegrationPayment clientSecret={clientSecret} /> */}
        <PaymentSuccess quantity={qty} priceObject={priceObject} />
      </SectionContainer>
    </PageContainer>
  )
}

export default IntegrationSuccess