import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const ApiIntegrationInfoSkeleton = () => {
  return (
    <div style={{height: "100%", display: "flex", alignItems: "center", flexDirection: "column", margin: "1em", marginTop:'10rem'}}>
      <SkeletonTheme baseColor="#ebedf6ec" >
        <p>
          <Skeleton height={"6em"} width={"6em"} circle/>
        </p>
      </SkeletonTheme>
      <SkeletonTheme baseColor="#ebedf6ec" >
        <p>
          <Skeleton height={"1.5em"} width={"10rem"} style={{marginTop:'8rem',justifyItems:'left',marginRight:'10rem',marginBottom:'1rem'}}/>
        </p>
      </SkeletonTheme>
      <SkeletonTheme baseColor="#ebedf6ec">
        <p>
          <Skeleton height={"1.5em"} width={"20rem"} count={5}/>
        </p>
      </SkeletonTheme>

    </div>
  );
};

export default ApiIntegrationInfoSkeleton;
