import React, { useState,useEffect } from 'react'
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import {useStripe, useElements, PaymentElement, PaymentRequestButtonElement} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
export const FintesaPlansCheckoutForm = (props) => {
    const navigate = useNavigate()
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false)
    const [paymentConfirmed, setPaymentConfirmed] = useState(false)
    const [paymentRequest, setPaymentRequest] = useState(null);
    useEffect(() => {
      if (stripe) {
        const pr = stripe.paymentRequest({
          country: 'US',
          currency: props.currency,
          total: {
            label: props.productName,
            amount:props.price ,
          },
          displayItems: [
            {
              label: props.productName,
              amount: props.price,
            }],
          requestPayerName: false,
          requestPayerEmail: false,
        });
        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
          if (result) {
            setPaymentRequest(pr);
          }
        });
        pr.on('paymentmethod', async (ev) => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
            props.clientSecret,
            {payment_method: ev.paymentMethod.id},
            {handleActions: false}
          );
        
          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent.status === "requires_action") {
              // Let Stripe.js handle the rest of the payment flow.
              const {error} = await stripe.confirmCardPayment(props.clientSecret);
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
              } else {
                // The payment has succeeded.
                setPaymentConfirmed(true)
              }
            } else {
              // The payment has succeeded.
            }
          }
        });
      }
      
    }, [stripe]);
    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
        setLoading(true)
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_FRONTEND_URL_USER}`,
        },
        redirect:'if_required'
      })
  
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.error(result.error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        setPaymentConfirmed(true)
      }
    };
  
    return (
      <>
      {paymentConfirmed ? 
      <>  <div className="container_success-checkmark_">
      <div className="success-checkmark">
        <div className="check-icon">
          <span className="icon-line line-tip"></span>
          <span className="icon-line line-long"></span>
          <div className="icon-circle"></div>
          <div className="icon-fix"></div>
        </div>
      </div>
      <div className="success_message">
        <h3>Thanks for your payment</h3>
        <p>A payment to fintesa.co will appear on your statement.</p>
        <div className="card_success_message">
          <span>FINTESA.CO</span>
          <span>${(props.price/100).toFixed(2)}</span>
        </div>
      </div>
    </div></>
      : <div style={{backgroundColor:'white', padding:'5rem 3rem', borderRadius:'1rem'}}>
        <Form.Label
        htmlFor="email"
        style={{ color: "rgb(48,49,61)", fontSize: "0.9rem" }}
      >
        Email
      </Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          id="email"
          aria-describedby="basic-addon3"
          style={{ color: "rgb(33,37,41)" }}
          required
          value={props.email}
          disabled={props.email ? true : false}
        />
      </InputGroup>
        <form onSubmit={handleSubmit} >
        { paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}} />}
         {paymentRequest && <hr />}
        <div><PaymentElement /></div>
        <Button type='submit' style={{width:'100%', marginTop:'1rem', maxWidth:'100%'}} disabled={!stripe || loading }>{loading ? <Spinner variant='light' size='sm' animation='border' /> : 'Subscribe'}</Button>
      </form>
        </div>}
      </>
    )
  };