import React, { useState } from 'react'
import PageContainer from '../components/UI/PageContainer'
import SectionContainer from '../components/UI/SectionContainer'
import SubscriptionInfo from '../components/IntegrationInformation/SubscriptionInfo'
import IntegrationPayment from '../components/IntegrationInformation/IntegrationPayment'
import IntegrationEmail from './IntegrationEmail'

const Integrations = () => {
  const [email, setEmail] = useState('')
  const [verified, setVerified] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [priceObject, setPriceObject] = useState(null);

  if (!verified) {
    return (
      <IntegrationEmail email={email} setEmail={setEmail} onPaymentTypeChange={setPaymentType} setVerfied={setVerified} setClientSecret={setClientSecret} setProductName={setProductName} setPriceObject={setPriceObject} setProductDescription={setProductDescription} />
    );
  }

  return (
    <PageContainer>
      <SectionContainer>
        <SubscriptionInfo productName={productName} priceObject={priceObject} productDescription={productDescription} />
      </SectionContainer>
      <SectionContainer>
        <IntegrationPayment clientSecret={clientSecret} email={email} />
      </SectionContainer>
    </PageContainer>
  )
}

export default Integrations