import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AxiosInstanceMENACartServer from '../components/axios/AxiosInstanceMENACartServer'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionPaymentContainer from '../components/payment_intent/SubscriptionPaymentContainer';
import { Spinner } from 'react-bootstrap';
import { SubscriptionPorductData } from '../components/subscription_payment/SubscriptionPorductData';

export const SubscriptionPayment = () => {
    const {uuid,clientSecret
    ,productId,priceId} = useParams()
    const [accountId, setAccountId] = useState('')
    const [product, setProduct] = useState('')
    const [price, setPrice] = useState('')
    const [description, setDescription] = useState('')
    useEffect( ()=>{
        async function loadingData(){
            await AxiosInstanceMENACartServer.get(`/get_merchent_id/${uuid}`).then( (res) => {
                setAccountId( res.data )
                if( res.data ){
                    return AxiosInstanceMENACartServer.post(
                        `/get-product`,
                        {
                          product_id:productId,
                          price_id: priceId,
                          merchant_id: res.data,
                        }
                      )
                }
            }).then( res => {
                if( res.data && res.data.price && res.data.price.unit_amount && res.data.price.currency){
                    setPrice( `${res.data.price.unit_amount/100} ${res.data.price.currency}`)
                }
                if( res.data && res.data.product && res.data.product.name ){
                    setProduct(res.data.product.name)
                }
                if( res.data && res.data.product && res.data.product.description){
                    setDescription( res.data.product.description )
                }
            })
            //Do nothing
        }
        loadingData()
    }, [])
  return (
  <div className='fintesa-plans-container'>
    { product && price && <SubscriptionPorductData accountId={accountId} product={product} price={price} description={description} />}
    {accountId ? <SubscriptionPaymentContainer accountId={accountId} clientSecret={clientSecret} /> : <Spinner animation='border' /> }
  </div>)
}
